import React, { useState, useEffect } from "react";
import { jarallax } from "jarallax";
import { LoaderContext } from "./LoaderContext";
import { Outlet } from "react-router-dom";

import logo from "../images/gardencitycabs.png";

const Layout = () => {
  const [hideLoader, setHideLoader] = useState(false);
  const [openMobileNav, setOpenMobileNav] = useState(false);
  const isMobile = window.matchMedia("(max-width: 992px)").matches;
  const currentYear = new Date().getFullYear();

  useEffect(() => {
    jarallax(document.querySelectorAll(".jarallax"));
    setHideLoader(true);
  }, []);

  return (
    <LoaderContext.Provider value={{ hideLoader, setHideLoader }}>
      <div id="wrapper">
        <div id="de-preloader" className={`${hideLoader ? "de-loader-hide" : ""}`}>
          <div className="de-preloader de-loader-in">
            <div className="car de-loader-in">
              <div className="strike de-loader-in"></div>
              <div className="strike strike2 de-loader-in"></div>
              <div className="strike strike3 de-loader-in"></div>
              <div className="strike strike4 de-loader-in"></div>
              <div className="strike strike5 de-loader-in"></div>
              <div className="car-detail spoiler de-loader-in"></div>
              <div className="car-detail back de-loader-in"></div>
              <div className="car-detail center de-loader-in"></div>
              <div className="car-detail center1 de-loader-in"></div>
              <div className="car-detail front de-loader-in"></div>
              <div className="car-detail wheel de-loader-in"></div>
              <div className="car-detail wheel wheel2 de-loader-in"></div>
              <div className="text de-loader-in">Loading...</div>
            </div>
          </div>
        </div>

        <div className="socialicons">
          <a href="https://www.facebook.com/profile.php?id=100008532228656" target="_blank">
            <i className="fa fa-facebook fa-lg"></i>
          </a>
          <a href="www.instagram.com/tv/CaZcaWWDqSG/?utm_medium=copy_link" target="_blank">
            <i className="fa fa-instagram fa-lg"></i>
          </a>
          <a href="https://youtu.be/LfmSuGY6NMs" target="_blank">
            <i className="fa fa-youtube fa-lg"></i>
          </a>
          <a href="https://wa.me/919902128888" target="_blank">
            <i className="fa fa-whatsapp fa-lg"></i>
          </a>
        </div>

        <header
          className={`transparent scroll-light has-topbar ${
            isMobile ? "header-mobile clone nav-up " : ""
          } ${openMobileNav ? "menu-open" : ""}`}
        >
          <div id="topbar" className="topbar-dark text-light">
            <div className="container">
              <div className="topbar-left xs-hide">
                <div className="topbar-widget">
                  <div className="topbar-widget">
                    <a href="tel:+919902128888">
                      <i className="fa fa-phone"></i>+91 9902128888
                    </a>
                  </div>
                  <div className="topbar-widget">
                    <a href="mailto:cabbooking@gardencitycabs.in">
                      <i className="fa fa-envelope"></i>cabbooking@gardencitycabs.in
                    </a>
                  </div>
                </div>
              </div>

              <div className="topbar-right"></div>
              <div className="clearfix"></div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="de-flex sm-pt10">
                  <div className="de-flex-col">
                    <div className="de-flex-col">
                      <div id="logo">
                        <a href="/">
                          <img className="logo-2" src={logo} alt="" />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="de-flex-col header-col-mid">
                    <ul id="mainmenu" className={openMobileNav ? "open-mobile-nav" : ""}>
                      <li>
                        <a className="menu-item" href="/">
                          Home
                        </a>
                      </li>
                      <li>
                        <a className="menu-item" href="/about-us">
                          About Us
                        </a>
                      </li>
                      {/* <li>
                        <a className="menu-item" href="/">
                          Services
                        </a>
                        <ul>
                          <li>
                            <a className="menu-item" href="/">
                              Overview
                            </a>
                          </li>
                          <li>
                            <a className="menu-item" href="/">
                              City Taxi
                            </a>
                          </li>
                          <li>
                            <a className="menu-item" href="/">
                              Airport Service
                            </a>
                          </li>
                          <li>
                            <a className="menu-item" href="/">
                              Tourist Cabs
                            </a>
                          </li>
                          <li>
                            <a className="menu-item" href="/">
                              Lost & Found
                            </a>
                          </li>
                          <li>
                            <a className="menu-item" href="/">
                              Safety Measures
                            </a>
                          </li>
                          <li>
                            <a className="menu-item" href="/">
                              Book a Cab Online
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <a className="menu-item" href="/">
                          Fleet
                        </a>
                      </li>
                      <li>
                        <a className="menu-item" href="#">
                          Place of Interest
                        </a>
                        <ul>
                          <li>
                            <a className="menu-item" href="/">
                              Bangalore
                            </a>
                          </li>
                          <li>
                            <a className="menu-item" href="/">
                              Mysore
                            </a>
                          </li>
                          <li>
                            <a className="menu-item" href="/">
                              Srirangapatna
                            </a>
                          </li>
                          <li>
                            <a className="menu-item" href="/">
                              Temples
                            </a>
                          </li>
                          <li>
                            <a className="menu-item" href="/">
                              Other Places
                            </a>
                          </li>
                        </ul>
                      </li> */}
                      <li>
                        <a className="menu-item" href="/contact-us">
                          Contact Us
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="de-flex-col">
                    <div className="menu_side_area">
                      <span id="menu-btn" onClick={() => setOpenMobileNav(!openMobileNav)}></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>

        <div className="no-bottom no-top" id="content">
          <div id="top"></div>
          <Outlet />
        </div>

        <a href="#" id="back-to-top"></a>

        <footer className="text-light">
          <div className="container">
            <div className="row g-custom-x">
              <div className="col-lg-3">
                <div className="widget">
                  <h5>About Gardencitycab</h5>
                  <p>
                    GCCabs is a reputed car rental service provider in Bangalore. We have all type
                    of A/C and non-A/C luxury tourist cabs for both personal and corporate use.
                  </p>
                </div>
              </div>

              <div className="col-lg-4">
                <div className="widget">
                  <h5>Contact Info</h5>
                  <address className="s1">
                    <span>
                      <i className="id-color fa fa-map-marker fa-lg"></i>#20, 7th Cross, Jaibheema
                      Nagar, 1st Stage, BTM Layout, Bengaluru Urban, Karnataka 560068, India
                    </span>
                    <span>
                      <i className="id-color fa fa-phone fa-lg"></i>
                      <a href="tel:+919902128888">+91 9902128888</a>
                    </span>
                    <span>
                      <i className="id-color fa fa-envelope-o fa-lg"></i>
                      <a href="mailto:cabbooking@gardencitycabs.in">cabbooking@gardencitycabs.in</a>
                    </span>
                  </address>
                </div>
              </div>

              <div className="col-lg-2">
                <h5>Quick Links</h5>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="widget">
                      <ul>
                        <li>
                          <a href="#">Home</a>
                        </li>
                        <li>
                          <a href="/about-us">About Us</a>
                        </li>
                        <li>
                          <a href="/contact-us">Contact Us</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-3">
                <div className="widget">
                  <h5>Social Network</h5>
                  <div className="social-icons">
                    <a
                      href="https://www.facebook.com/profile.php?id=100008532228656"
                      target="_blank"
                    >
                      <i className="fa fa-facebook fa-lg"></i>
                    </a>
                    <a
                      href="www.instagram.com/tv/CaZcaWWDqSG/?utm_medium=copy_link"
                      target="_blank"
                    >
                      <i className="fa fa-instagram fa-lg"></i>
                    </a>
                    <a href="https://youtu.be/LfmSuGY6NMs" target="_blank">
                      <i className="fa fa-youtube fa-lg"></i>
                    </a>
                    <a href="https://wa.me/919902128888" target="_blank">
                      <i className="fa fa-whatsapp fa-lg"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="subfooter">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="de-flex">
                    <div className="de-flex-col">
                      <a href="/">{currentYear} Garden City Cab. All rights reserved.</a>
                    </div>
                    <ul className="menu-simple">
                      <li>
                        <a href="#">Terms &amp; Conditions</a>
                      </li>
                      <li>
                        <a href="#">Privacy Policy</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </LoaderContext.Provider>
  );
};

export default Layout;

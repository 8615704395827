import React, { useState } from "react";
import img1 from "../images/background/subheader.jpg";
import img7 from "../images/misc/car.png";

const AboutUs = () => {
  const [activeTab, setActiveTab] = useState("pills-home");

  return (
    <>
      <section id="subheader" className="jarallax text-light">
        <img src={img1} className="jarallax-img" alt="" />
        <div className="center-y relative text-center">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center">
                <h1>About Us</h1>
              </div>
              <div className="clearfix"></div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="row g-5">
            <div className="col-lg-6 fadeInRight">
              <h2>
                We offer customers a wide range of <span className="id-color">commercial cars</span>{" "}
                and <span className="id-color">luxury cars</span> for any occasion.
              </h2>
            </div>
            <div className="col-lg-6 fadeInRight" data-wow-delay=".25s">
              GCCabs is a reputed car rental service provider in Bangalore. We have all type of A/C
              and non-A/C luxury tourist cabs for both personal and corporate use. We offer all
              types of travel related services under one roof and are known for our excellent and
              efficient taxi services in Bangalore. We are committed to provide safe and quick
              service. Our cabs are available round the clock and are backed by location tracking.
              This means a quicker pickup and faster response time to your call for cabs. We offer
              services like Airport transfer, point to point services, hourly package, outstation
              package for corporate executives to attend meetings, conventions, weddings, and social
              events.
            </div>
          </div>
          <div className="spacer-double"></div>
          <div className="row text-center">
            <div className="col-md-3 col-sm-6 mb-sm-30">
              <div className="de_count fadeInUp" data-bgcolor="#f5f5f5">
                <h3 className="timer" data-to="15425" data-speed="3000">
                  15425
                </h3>
                Completed Orders
              </div>
            </div>
            <div className="col-md-3 col-sm-6 mb-sm-30">
              <div className="de_count fadeInUp" data-bgcolor="#f5f5f5">
                <h3 className="timer" data-to="8745" data-speed="3000">
                  400+
                </h3>
                Happy Customers
              </div>
            </div>
            <div className="col-md-3 col-sm-6 mb-sm-30">
              <div className="de_count fadeInUp" data-bgcolor="#f5f5f5">
                <h3 className="timer" data-to="235" data-speed="3000">
                  120
                </h3>
                Vehicles Fleet
              </div>
            </div>
            <div className="col-md-3 col-sm-6 mb-sm-30">
              <div className="de_count fadeInUp" data-bgcolor="#f5f5f5">
                <h3 className="timer" data-to="15" data-speed="3000">
                  15+
                </h3>
                Years Experience
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="section-img-with-tab">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-5 offset-lg-7">
              <h2>Only Quality For Clients</h2>
              <div className="spacer-20"></div>

              <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button
                    className={`nav-link ${activeTab === "pills-home" ? "active" : ""}`}
                    id="pills-home-tab"
                    onClick={() => setActiveTab("pills-home")}
                    type="button"
                    role="tab"
                    aria-controls="pills-home"
                    aria-selected={activeTab === "pills-home"}
                  >
                    Luxury
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className={`nav-link ${activeTab === "pills-profile" ? "active" : ""}`}
                    id="pills-profile-tab"
                    onClick={() => setActiveTab("pills-profile")}
                    type="button"
                    role="tab"
                    aria-controls="pills-profile"
                    aria-selected={activeTab === "pills-profile"}
                  >
                    Comfort
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className={`nav-link ${activeTab === "pills-contact" ? "active" : ""}`}
                    id="pills-contact-tab"
                    onClick={() => setActiveTab("pills-contact")}
                    type="button"
                    role="tab"
                    aria-controls="pills-contact"
                    aria-selected={activeTab === "pills-contact"}
                  >
                    Prestige
                  </button>
                </li>
              </ul>
              <div className="tab-content" id="pills-tabContent">
                <div
                  className={`tab-pane fade ${activeTab === "pills-home" ? "show active" : ""}`}
                  id="pills-home"
                  role="tabpanel"
                  aria-labelledby="pills-home-tab"
                >
                  <p>
                    We offer a meticulously curated collection of the most sought-after luxury
                    vehicles on the market. Whether you prefer the sporty allure of a
                    high-performance sports car, the sophistication of a sleek and luxurious sedan,
                    or the versatility of a premium SUV, we have the perfect car to match your
                    discerning taste.
                  </p>
                </div>
                <div
                  className={`tab-pane fade ${activeTab === "pills-profile" ? "show active" : ""}`}
                  id="pills-profile"
                  role="tabpanel"
                  aria-labelledby="pills-profile-tab"
                >
                  <p>
                    We prioritize your comfort and convenience throughout your journey. We
                    understand that a comfortable ride can make a world of difference, whether
                    you're embarking on a business trip or enjoying a leisurely vacation. That's why
                    we offer a wide range of well-maintained, comfortable cars that cater to your
                    specific needs.
                  </p>
                </div>
                <div
                  className={`tab-pane fade ${activeTab === "pills-contact" ? "show active" : ""}`}
                  id="pills-contact"
                  role="tabpanel"
                  aria-labelledby="pills-contact-tab"
                >
                  <p>
                    We understand that prestige goes beyond luxury. It's about making a statement,
                    embracing sophistication, and indulging in the finer things in life. That's why
                    we offer an exclusive selection of prestigious cars that exude elegance, style,
                    and status.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="image-container col-md-6 ">
          <iframe
            width="100%"
            height="450"
            src="https://www.youtube.com/embed/X-T-Bx_xKJc?si=iQhDxRNN6SD9CLFw"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </section>

      <section aria-label="section" id="about-us-whydiv">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 offset-lg-3 text-center">
              <h2>Features Hilight</h2>
              <div className="spacer-20"></div>
            </div>
            <div className="clearfix"></div>
            <div className="col-lg-3">
              <div className="box-icon s2 p-small mb20 fadeInRight" data-wow-delay=".5s">
                <i className="fa bg-color fa-trophy"></i>
                <div className="d-inner">
                  <h4>First class services</h4>
                  Where luxury meets exceptional care, creating unforgettable moments and exceeding
                  your every expectation.
                </div>
              </div>
              <div className="box-icon s2 p-small mb20 fadeInL fadeInRight" data-wow-delay=".75s">
                <i className="fa bg-color fa-road"></i>
                <div className="d-inner">
                  <h4>24/7 road assistance</h4>
                  Reliable support when you need it most, keeping you on the move with confidence
                  and peace of mind.
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <img src={img7} alt="" className="img-fluid fadeInUp" />
            </div>

            <div className="col-lg-3">
              <div
                className="box-icon s2 d-invert p-small mb20 fadeInL fadeInLeft"
                data-wow-delay="1s"
              >
                <i className="fa bg-color fa-tag"></i>
                <div className="d-inner">
                  <h4>Quality at Minimum Expense</h4>
                  Unlocking affordable brilliance with elevating quality while minimizing costs for
                  maximum value.
                </div>
              </div>
              <div
                className="box-icon s2 d-invert p-small mb20 fadeInL fadeInLeft"
                data-wow-delay="1.25s"
              >
                <i className="fa bg-color fa-map-pin"></i>
                <div className="d-inner">
                  <h4>Free Pick-Up & Drop-Off</h4>
                  Enjoy free pickup and drop-off services, adding an extra layer of ease to your car
                  rental experience.
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="section-call-to-action" className="bg-color-2 pt60 pb60 text-light">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-8">
              <h2 className="s2">
                Call us for further information. Gardencitycab customer care is here to help you
                anytime.
              </h2>
            </div>

            <div className="col-lg-4 text-lg-center text-sm-center">
              <div className="phone-num-big">
                <i className="fa fa-phone"></i>
                <span className="pnb-text">Call Us Now</span>
                <span className="pnb-num">9902128888</span>
              </div>
              <a href="#" className="btn-main">
                Contact Us
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutUs;
